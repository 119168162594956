import {
  configureStore
} from '@reduxjs/toolkit'

import reducer from './redux'

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    thunk: false
  })
})

export default store
