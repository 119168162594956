import React, { Suspense } from 'react'
import {
  BrowserRouter as Router
} from 'react-router-dom'
import LoaderRound from '../components/LoaderRound'
import CustomRoutes from './routes'

const Routes = () => (
  <Router>
    <Suspense fallback={<LoaderRound />}>
      <CustomRoutes />
    </Suspense>
  </Router>
)

export default Routes
