import { combineReducers } from '@reduxjs/toolkit'
import user from './user'
import gameSetting from './gameSetting'
import betHistory from './betHistory'
import gameSocketData from './gameSocketData'

const rootReducer = combineReducers({
  user,
  gameSetting,
  betHistory,
  gameSocketData
})

export default rootReducer
