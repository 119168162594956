import { lazy } from 'react'
const CrashGameLanding = lazy(() => import('../containers/GameLandingPage'))

const routesList = [{
  path: '/',
  component: CrashGameLanding
}, {
  path: '/crash',
  component: CrashGameLanding
}]

export default routesList
