import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (_error) {
    console.log(_error)
    return { hasError: true }
  }

  render () {
    const { t } = this.props
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='main-err-wrapper' style={{ height: '100vh' }}>
          <div className='pt-5 static-root page-not-found bg-container text-white'>
            <h2 className='text-center'>
              <i className='pr-2 fa fa-exclamation-triangle' aria-hidden='true' />
              {t('error')}
            </h2>
            <div className='mt-3 notFound text-center'>
              <p>
                {t('somethingWentWrong')}
              </p>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default withTranslation('home')(ErrorBoundary)
