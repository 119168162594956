import React from 'react'
import ReactDOM from 'react-dom'
import './styles/CrashGame.css'
import App from './App'
import './config/i18n'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import queryClient from './reactQuery/queryClientSetup'
const urlType = process.env.REACT_APP_NODE_ENV

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      {urlType === 'testing' && (<ReactQueryDevtools initialIsOpen={false} position='bottom-left' />)}
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
