import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationsEN from './locales/en/index'

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    saveMissing: true,
    resources: {
      en: {
        ...translationsEN
      }
    },
    // lng: loginSession.getLocale || 'en',
    nsSeparator: '::',
    interpolation: {
      escapeValue: false
    }
  })

const runTimeTranslations = (runTimeData, lng) => {
  i18n.addResourceBundle(lng, ['main'], runTimeData)
}

export { i18n, runTimeTranslations }
