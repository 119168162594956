import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  betHistoryData: [],
  resultData: [],
  currentRoundId: ''
}

const {
  actions: {
    setPlacedBetsData, setResultData, setCurrentRoundId

  },
  reducer
} = createSlice({
  name: 'betHistory',
  initialState,
  reducers: {
    setPlacedBetsData: (state, action) => {
      return {
        ...state,
        betHistoryData: action.payload
      }
    },
    setResultData: (state, action) => {
      return {
        ...state,
        resultData: action.payload
      }
    },
    setCurrentRoundId: (state, action) => {
      return {
        ...state,
        currentRoundId: action.payload
      }
    }
  }
})

export default reducer
export {
  setPlacedBetsData, setResultData, setCurrentRoundId
}
